import { color } from './color';
import { jobOfferStatusKAM } from './jobOfferStatusKAM';
import { tooltip } from './tooltip';

const header = {
  height: {
    desktop: '80px',
    mobile: '50px',
  },
  bg: color.white,
};

export const defaultTheme = {
  typography: 'Montserrat',
  color,
  header,
  tooltip,
  primaryColor: color.orangeOne,
  shadowColor: 'rgba(0, 0, 0, 0.25)',
  text: {
    color: {
      default: color.dark,
      description: color.grayOne,
      secondary: color.grayTwo,
      secondaryLight: color.grayTwoLight,
      secondaryUltraLight: color.grayFour,
      grayFive: color.grayFive,
      light: color.neutral,
      error: color.red,
      disabled: color.grayThree,
      turquoise: color.turquoiseOne,
      orange: color.orangeOne,
      green: color.green,
    },
    type: {
      h1: {
        lineHeight: '1.5',
        screenSize: {
          mobile: {
            fontSize: '32px',
          },
          desktop: {
            fontSize: '32px',
          },
        },
        padding: '0 0 30px 0',
      },
      h2: {
        lineHeight: '1.5',
        screenSize: {
          mobile: {
            fontSize: '22px',
          },
          desktop: {
            fontSize: '22px',
          },
        },
        padding: '0 0 20px 0',
      },
      h3: {
        lineHeight: '1.3',
        screenSize: {
          mobile: {
            fontSize: '16px',
          },
          desktop: {
            fontSize: '16px',
          },
        },
        padding: '0 0 10px 0',
      },
      p: {
        lineHeight: '1.2',
        screenSize: {
          mobile: {
            fontSize: '14px',
          },
          desktop: {
            fontSize: '14px',
          },
        },
        padding: '0',
      },
      span: {
        lineHeight: '1.2',
        screenSize: {
          mobile: {
            fontSize: '14px',
          },
          desktop: {
            fontSize: '14px',
          },
        },
        padding: '0',
      },
      'p-12': {
        lineHeight: '1.2',
        screenSize: {
          mobile: {
            fontSize: '12px',
          },
          desktop: {
            fontSize: '12px',
          },
        },
        padding: '0',
      },
      'p-11': {
        lineHeight: '1.1',
        screenSize: {
          mobile: {
            fontSize: '11px',
          },
          desktop: {
            fontSize: '11px',
          },
        },
        padding: '0',
      },
    },
    weight: {
      extraBold: '800',
      bold: '700',
      regular: '400',
    },
    alignment: {
      center: {
        justifyContent: 'center',
      },
      start: {
        justifyContent: 'flex-start',
      },
    },
    textAlignment: {
      center: {
        textAlign: 'center',
      },
      left: {
        textAlign: 'left',
      },
      justify: {
        textAlign: 'justify',
      },
    },
  },

  button: {
    type: {
      default: {
        backgroundColor: color.orangeOne,
        color: color.neutral,
        borderColor: color.orangeOne,
        textDecoration: 'none',
      },
      outline: {
        backgroundColor: 'transparent',
        color: color.dark,
        borderColor: color.orangeOne,
        textDecoration: 'none',
      },
      disabled: {
        backgroundColor: color.orangeFour,
        color: color.neutral,
        borderColor: color.orangeFour,
        textDecoration: 'none',
      },
      text: {
        backgroundColor: 'transparent',
        color: color.orangeOne,
        borderColor: 'transparent',
        textDecoration: 'none',
      },
      darkText: {
        backgroundColor: 'transparent',
        color: color.dark,
        borderColor: 'transparent',
        textDecoration: 'none',
      },
      hover: {
        backgroundColor: color.orangeThree,
        color: color.white,
        borderColor: color.orangeThree,
        textDecoration: 'none',
      },
      underline: {
        backgroundColor: 'transparent',
        color: color.orangeOne,
        borderColor: 'transparent',
        textDecoration: 'underline',
      },
      underlineGray: {
        backgroundColor: 'transparent',
        color: color.grayTwoLight,
        borderColor: 'transparent',
        textDecoration: 'underline',
      },
      outlineGray: {
        backgroundColor: 'transparent',
        color: color.grayTwo,
        borderColor: color.grayTwo,
        textDecoration: 'none',
      },
      dark: {
        backgroundColor: color.dark,
        color: color.white,
        borderColor: color.dark,
        textDecoration: 'none',
      },
      disable: {
        backgroundColor: 'transparent',
        color: color.red,
        borderColor: 'transparent',
        textDecoration: 'underline',
      },
    },
    sizes: {
      sm: {
        fontSize: '13px',
        lineHeight: '13px',
        width: '70px',
        height: '24px',
        padding: '0 14px',
      },
      md: {
        fontSize: '14px',
        lineHeight: '21px',
        width: '171px',
        height: '28px',
        padding: '0 16px',
      },
      lg: {
        fontSize: '16px',
        lineHeight: '24px',
        width: '272px',
        height: '32px',
        padding: '0 16px',
      },
      xl: {
        fontSize: '16px',
        lineHeight: '24px',
        width: '272px',
        height: '46px',
        padding: '0 16px',
      },
    },
    borderRadius: {
      pill: '20px',
      normal: '5px',
    },
  },

  modal: {
    size: {
      default: {
        width: 'auto',
        height: 'auto',
      },
      xs: {
        width: '470px',
        height: '336px',
      },
      sm: {
        width: '592px',
        height: '428px',
      },
      md: {
        width: '640px',
        height: '600px',
      },
      lg: {
        width: '740px',
        height: '700px',
      },
    },
    borderRadius: {
      default: '5px',
      pill: '20px',
    },
  },

  input: {
    type: {
      default: {
        height: '48px',
        width: '100%',
        color: color.dark,
        border: `1px solid ${color.dark}`,
        borderWidth: '1px 0 1px 0',
        borderStyle: 'solid',
        borderColor: color.dark,
        labelSize: '18px',
        labelWeight: 'medium',
        borderRadius: '30px',
        margin: '0',
        padding: '0 12px',
      },
      textLine: {
        height: '40px',
        width: '100%',
        color: color.dark,
        borderWidth: '0 0 1px 0',
        borderStyle: 'solid',
        borderColor: color.dark,
        borderBottom: `1px solid ${color.dark}`,
        labelSize: '18px',
        labelWeight: 'medium',
        borderRadius: '0',
        margin: '0',
        padding: '0 12px',
      },
      textLineWhite: {
        height: 'fit-content',
        width: '250px',
        color: color.white,
        borderWidth: '0 0 1px 0',
        borderStyle: 'solid',
        borderColor: color.white,
        borderBottom: `1px solid ${color.white}`,
        labelSize: '18px',
        labelWeight: 'medium',
        borderRadius: '0',
        margin: '0',
        padding: '0',
      },
    },
    placeholder: '#5A5E62',
    labelColor: {
      default: {
        color: color.dark,
      },
      selected: {
        color: color.grayOne,
      },
      error: {
        color: color.red,
      },
    },
  },

  inputSelect: {
    type: {
      default: {
        color: color.white,
        borderColor: color.dark,
        labelSize: '18px',
        labelWeight: 'medium',
      },
      textLine: {
        color: color.white,
        borderColor: color.dark,
        border: 'none',
        labelSize: '18px',
        labelWeight: 'medium',
      },
      disabled: {
        color: color.grayThree,
        borderColor: color.grayThree,
        labelSize: '18px',
        labelWeight: 'medium',
      },
      error: {
        color: color.red,
        borderColor: color.red,
        labelSize: '18px',
        labelWeight: 'medium',
      },
    },
    labelColor: {
      default: {
        color: color.dark,
      },
      selected: {
        color: color.grayOne,
      },
      error: {
        color: color.red,
      },
    },
  },
  avatar: {
    size: {
      sm: {
        width: '50px',
        height: '50px',
        position: 'relative',
        margin: '0px',
        marginTop: '0px',
        border: 'none',
      },
      md: {
        width: '140px',
        height: '140px',
        position: 'relative',
        margin: '0px',
        marginTop: '10px',
        border: 'none',
      },
      bg: {
        width: '140px',
        height: '140px',
        position: 'absolute',
        margin: '0px',
        marginTop: '70px',
        border: 'solid #FFFFFF 5px',
      },
    },
    type: {
      button: {
        cursor: 'pointer',
      },
      image: {
        cursor: 'default',
      },
    },
  },

  switch: {
    bg: {
      actived: color.orangeOne,
      deactivated: color.grayThree,
    },
    size: {
      md: {
        width: '44px',
        height: '24px',
      },
      lg: {
        width: '56px',
        height: '32px',
      },
    },
    icon: {
      size: {
        md: {
          width: '20px',
          height: '20px',
        },
        lg: {
          width: '26px',
          height: '26px',
        },
      },
      bg: color.neutral,
      margin: {
        md: '2px', // (switch height - icon height) / 2
        lg: '3px',
      },
      transform: {
        md: '20px', // switch width - switch height
        lg: '23px',
      },
    },
  },
  table: {
    size: {
      md: {
        width: '44px',
        height: '24px',
      },
      lg: {
        width: '1143px',
        height: '56px',
      },
    },
    filters: {
      color: color.dark,
    },
    header: {
      color: color.grayFiveLight,
    },
    body: {
      color: color.white,
      size: {
        md: {
          width: '44px',
          height: '24px',
        },
        lg: {
          width: '1143px',
          height: '365px',
        },
      },
    },
    statusBarColor: {
      1: color.orangeOne,
      2: color.orangeTwo,
      3: color.yellow,
      4: color.green,
    },
  },

  securityBar: {
    color: [color.grayFive, color.red, color.yellow, color.lightGreen, color.green],
    heigth: '8px',
  },

  form: {
    type: {
      default: {
        display: 'grid',
        gap: '32px',
        padding: '34px 50px',
        backgroundColor: color.white,
        borderRadius: '12px',
        marginBottom: '0',
      },
      customerForm: {
        display: 'grid',
        gap: '18px',
        padding: '0',
        backgroundColor: 'transparent',
        borderRadius: '12px',
        marginBottom: '50px',
      },
      justifyEndForm: {
        display: 'grid',
        gap: '18px',
        padding: '0',
        backgroundColor: 'transparent',
        borderRadius: '12px',
        marginBottom: '50px',
      },
    },
  },

  jobOfferStatusKAM,
};
