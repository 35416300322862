import { IBaseDB } from './General';
import { ICandidate, ICreateCandidate } from './ICandidate';
import { ICandidateOfferBase as ICandidateOffer } from './ICandidateOffer';
import { IJobOffer } from './IJobOffer';
import { ISkill } from './ISkill';
import { IGeneralUsers } from './IUser';

export enum CANDIDATE_STATUS {
  PENDING = 'Pendiente',
  SENT = 'Enviado',
  REJECTED = 'Rechazado',
  DISCARDED = 'Descartado',
  HIRED = 'Contratado',
}

export enum CANDIDATE_STAGE {
  NULL,
  START,
  EVALUATIONS,
  OFFERS,
}

export interface ICreateCandidateJobOffer {
  jobOffer: string;
  company: string;
  candidate: ICreateCandidate;
  skills: ISkill[];
  cv?: File;
}

export interface ICandidateJobOffer extends Pick<ICreateCandidateJobOffer, 'company' | 'skills'>, IBaseDB {
  jobOffer: IJobOffer;
  candidate: ICandidate;
  registeredBy: IGeneralUsers;
  compatibilityPercentage: number;
  status: CANDIDATE_STATUS;
  stage: CANDIDATE_STAGE;
  expectedSalary?: number;
  availability?: string;
  comments?: string;
  reasonForRejection?: string;
  offersSent?: ICandidateOffer[];
}
